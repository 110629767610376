@import 'shared/constants';

* {
    margin : 0;
    padding : 0;
    border : none;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}

body {
    color: $dark_gray;
    text-align: center;
}

p {
    color: $dark_gray;
    font-size: 17px;
    line-height: 28px;
    margin: 20px 0 10px 0;
    font-weight: 300;
}

h1 {
    color: $primary;
    margin-bottom: 30px;
    word-wrap: break-word;
}

button{
    font-size: 110%;
}

#app-root {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

html {
    background: url('./assets/background.png') no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

html, body, #root {
    height: 100%;
    width: 100%;
}