@import '../../../../shared/constants';

label{
    margin-top: 20px;
    display: block;
    font-size: 15px; 
    margin-bottom: 10px;
    color: #405364;
    font-weight: 300;

    small {
        font-size: 13px;
    }
}