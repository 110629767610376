
.dialog-content {
    margin-bottom: 20px;

    &.start {
        text-align: start;
    }

    &.center {
        text-align: center;
    }

    &.end {
        text-align: end;
    }
}