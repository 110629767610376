@import '../../../../shared/constants';

textarea { 	
	resize: vertical;
	border: 1px solid $light_gray;
	width: 100%;
	min-height: 80px; 
	padding: 10px;
    color: rgba(64,64,64,1.00); 
    font-size: 15px;
	font-family: 'Roboto', sans-serif; 
	font-weight: 300;
	border-radius: 10px;
	outline: none;
	box-sizing: border-box;
	border-width: 2px;

	&:focus {
		border-color: $primary;
	}
}