
.option {
    background-color: #E5E9EC;
    color: #405364;
    height: 50px;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 14px;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    margin-top: 10px;
    margin-right: 10px;
    min-width: 75px;

    &.selected {
        background-color: #2DD477;
        color: #FFFFFF;
    }
}