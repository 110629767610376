
#dialog-wrapper {
    background-color: #FFFFFF;
    width: 60vw;
    max-width: 600px;
    margin-bottom: 20px;
    border-radius: 20px;
    margin-top:20px;

    @media only screen and (max-width: 700px) {
        width: 90%;
    }

    #dialog-inner {
        padding: 10%;
    }
}
