
.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    background-color: rgba($color: #000000, $alpha: 0.075);

    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);

    .overlay-content {
        background-color: #fff;
        border-radius: 10px;
        max-width: 500px;
        width: 90%;
        margin: 15vh auto auto auto;
        position: absolute;
        padding: 30px 5%;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
        
        -webkit-box-shadow: 0px 0px 30px 0px rgba(50, 50, 50, 0.3);
        -moz-box-shadow:  0px 0px 30px 0px rgba(50, 50, 50, 0.3);
        box-shadow: 0px 0px 30px 0px rgba(50, 50, 50, 0.3);
    }
}