@import '../../../../shared/constants';

label p {
    display: block;
    font-size: 15px; 
    margin-bottom: 5px;
    color: $medium_gray;
    font-weight: 100;
}

.picture-file { 
    cursor: pointer;
    border: 2px solid $light_gray;
    border-radius: 10px;
    background: url(./assets/picture.jpg) no-repeat center center / 50px;
    width: 100%;
    height: 250px; 
    margin-bottom: 20px;
    text-align: center;
}

.preview-file { 
    height: 100%; 
}