@import '../../shared/constants.scss';

.btn {
    background-color: $primary;
    color: #FFFFFF;
    height: 50px;
    padding: 10px 40px;
    border-radius: 10px;
    font-weight: 500;
    cursor: pointer;
    outline: none;
    overflow: hidden;
    margin-top: 10px;
    text-decoration: none;

    &.stretch {
        width: 100%;
    }

    &.inverted {
        background-color: #FFFFFF;
        color: $primary;
        border-width: ".0625rem";
        border-color: $primary;
        border-style: solid;
    }

    &:not(.inverted):hover {
        background-color: $secondary;
    }

    img {
        height: 100%;
    }
}

button:disabled {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
    background-color: #d6dce5
}

.btn-content {
    cursor: default;
    transform: translateY(-40px);
}