@import '../../shared/constants';

.medicheck-input {
    padding: 3px 0 7px;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;

    input[type="text"] {
        width: 50%;
    }

    .line input {
        border-bottom: 1px solid $medium_gray;
    }

    .error input {
        border-bottom: 1px solid $red;
        margin-bottom: 0;
    }

    &select, &textarea {
        color: rgba(64,64,64,1.00);
    }
}

::placeholder {
    color : rgba(64, 83, 100, 0.4);
    opacity: 1;
}   

:-ms-input-placeholder {
    color : rgba(64, 83, 100, 0.4);
}

.error {
    color: $red; 
    font-size: 12px; 
    margin-bottom: 20px;
    margin-top: 0px;
}

.MuiFormHelperText-root {
    display: none;
}